import { Button, Modal } from "react-bootstrap"

const BookLanguageModal = (props) => {
    const { updateShowLangOption, navigateToBook } = props
    return (
        <div className="language-modal">
            <Modal show={true} onHide={() => updateShowLangOption()} animation={false} size="md" centered>
                <Modal.Header closeButton className="lang-modal-header">
                    <Modal.Title>Please Select a language of book</Modal.Title>
                </Modal.Header>
                {/* <Modal.Body className="lang-modal-body">
                    <div className="all-btns-wrap">
                        {["English", "Urdu", "Spanish", "German", "Hindi", "Arabic"].map((lang, i) => <Button key={i} className="all-btns" variant="warning" onClick={() => navigateToBook(lang.toLowerCase())}>{lang}</Button>)}
                    </div>
                </Modal.Body> */}
                <Modal.Body className="lang-modal-body">
                    <div className="all-btns-wrap">
                      {/* {["English", "Urdu", "Spanish", "German", "Hindi", "Arabic"].map((lang) => { */}
                      {["English", "Urdu", "Hindi", "Arabic"].map((lang) => {
                        const isEnglish = lang === "English";
                        return (
                          <Button
                            key={lang}
                            className="all-btns"
                            variant="warning"
                            disabled={!isEnglish}
                            onClick={() => isEnglish && navigateToBook(lang.toLowerCase())}
                          // style={{ lineHeight: '1.1', padding: '4px 4px' }} // Adjust padding as needed
                          >
                            {isEnglish ? (
                              lang
                            ) : (
                              <>
                                {lang}
                                <br />
                                <span style={{ fontSize: '11px', display: 'inline-block', marginTop: '2px' }}>
                                  (Coming Soon)
                                </span>
                              </>
                            )}
                          </Button>
                        );
                      })}
                    </div>
                  </Modal.Body>
            </Modal>
        </div >
    )
}

export default BookLanguageModal