import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"

import DashboardNavbar from "../DashboardNavbar/DashbaordNavbar"
import DashboardMenu from "../MenuBar/dashboardMenu"
import { updateOrInsertBookLanguageStats } from "../../Helpers/statsApiService"
import PasswordConfirmation from "../PasswordConfirmation/PasswordConfirmation"
import BookData from "./BookData"
import RandomColor from "../../utils/randomColor"
import getAge from "../../utils/getAge"
import BookLanguageModal from "../../utils/bookLanguageModal"
import DashboardBook from "./DashboardBook"

import donut_dark from "../../assets/dashboard/donut.png"
import donut_dark_bottom from "../../assets/dashboard/Donut-light-big.png"

const config = require("../../Helpers/config.json")

const NewDashboard = () => {
  const navigate = useNavigate()
  const readingProgressRef = useRef(null)
  const [selectedBook, setSelectedBook] = useState(null)
  const [showLangOption, setShowLangOption] = useState(false)
  const [description, setDescription] = useState("Reading books is a enriching activity where individuals engage with written content to gain knowledge, entertainment, and insights. It fosters literacy, critical thinking, and imagination, providing a means to explore diverse ideas, perspectives, and experiences. Whether for education or leisure, reading contributes to personal growth and enjoyment.")
  const [loading, setLoading] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [fadeClass, setFadeClass] = useState("fade-in")
  const [hoveredIndex0, setHoveredIndex0] = useState(null)
  const [readingBook, setReadingBook] = useState([])
  const [allBooks, setAllBooks] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [hoverLeave, setHoverLeave] = useState(false)
  const [topSectionData, setTopSectionData] = useState([])
  const setDesktopViewport = () => {
    const viewportMetaTag = document.querySelector('meta[name="viewport"]');
    viewportMetaTag.content = "width=1024"; // Set the width to your desired desktop width
  };

  useEffect(() => {
    setDesktopViewport();
    return () => {
      const viewportMetaTag = document.querySelector('meta[name="viewport"]');
      viewportMetaTag.content = "width=device-width, initial-scale=1.0";
    };
  }, []);

  const scrollRefs = useRef([])

  const newData = localStorage.getItem("data")
  const profileID = localStorage.getItem("profileId")
  const formatData = JSON.parse(newData)

  const getStoryBookLanguages = async (item) => {
    await setSelectedBook(item)
    await setShowLangOption(true)
  }

  const navigateToBook = (lang) => {
    navigate(`/book-detail?lang=${lang}`, { state: { SelectedBook: selectedBook, isFromNew: true } })
    updateOrInsertBookLanguageStats(localStorage.getItem("id"), localStorage.profileId, selectedBook.id, 1, lang)
  }

  const getBookReadingProgress = async () => {
    try {
      const res = await axios.get(`${config["baseUrlAws"]}/api/stats/books/getBookReadingProgress/?userId=${formatData?.uid}&profileId=${profileID}`)
      if (res && res?.data && res?.data?.data) {
        setReadingBook(res?.data?.data)
      }
    } catch (error) {
      console.log("Error while getting book reading progress", { error })
    }
  }

  const fetchBooks = async () => {
    try {
      await setLoading(true);
      const response = await axios.get(`${config["baseUrlAws"]}/s3/book/${process.env.REACT_APP_ORGANIZATION_NAME}`)
      let { data } = await response?.data
      let currentAge = await localStorage.getItem("dob")
      // let kidAge = await getAge(currentAge)
      // console.log("kidAge", kidAge);
      // data = await data?.map(d => {
      //   for (let i = 0; i < d?.pdfs?.length; i++) {
      //     console.log("d?.pdfs[i].path", d?.pdfs[i].pdfPath);
      //     console.log("d?.pdfs[i]?.grade", d?.pdfs[i]?.grade);
      //     if (d?.pdfs[i]?.grade === 6 && kidAge >= 8) {
      //       return { ...d, pdfs: [{ ...d?.pdfs[i] }] }
      //     } else if (d?.pdfs[i]?.grade === 4 && kidAge < 8) {
      //       return { ...d, pdfs: [{ ...d?.pdfs[i] }] }
      //     } else if (d?.pdfs[i]?.grade === null) {
      //       return { ...d }
      //     }
      //   }
      // })

      let kidAge = await getAge(currentAge);
      // console.log("kidAge:", kidAge);

      data = await data?.map(d => {
        let selectedPdf = null;

        if (d?.pdfs?.length > 0) {
          // console.log(`🔍 Checking PDFs for story: ${d?.title || "Unknown Story"}`);
          let pdfForOlderKids = null;
          let pdfForYoungerKids = null;

          d.pdfs.forEach(pdf => {
            let match = pdf.pdfPath.match(/(\d+)(?=\.pdf)/); // Extract number before ".pdf"
            let pdfAge = match ? parseInt(match[1], 10) : null;
            // console.log(`📄 Found PDF: ${pdf.pdfPath}`);
            // console.log(`🔢 Extracted Age: ${pdfAge !== null ? pdfAge : "No age found"}`);

            if (pdfAge !== null) {
              if (pdfAge > 6) {
                // console.log("🟠 This PDF is for older kids (age > 6).");
                pdfForOlderKids = pdf;
              } else {
                // console.log("🟢 This PDF is for younger kids (age ≤ 6).");
                pdfForYoungerKids = pdf;
              }
            } else {
              // console.log("⚪ No age found in PDF path.");
            }
          });

          // Selecting the right PDF based on kidAge
          if (kidAge >= 8 && pdfForOlderKids) {
            // console.log("✅ Kid is 8 or older, selecting the PDF for older kids.");
            selectedPdf = pdfForOlderKids;
          } else if (kidAge < 8 && pdfForYoungerKids) {
            // console.log("✅ Kid is younger than 8, selecting the PDF for younger kids.");
            selectedPdf = pdfForYoungerKids;
          } else {
            // console.log("🔄 No suitable PDF based on age, defaulting to the first available PDF.");
            selectedPdf = d.pdfs[0]; // Default to the first available PDF
          }
        } else {
          // console.log("⚠️ No PDFs found for this story.");
        }

        return { ...d, pdfs: selectedPdf ? [selectedPdf] : [] };
      });


      // Check if subscription is active
      const isSubscriptionActive = true;
      let filteredData = [];
      if (!isSubscriptionActive) {
        const staticBookIds = [17, 34, 35]
        for (let i = 0; i < data.length; i++) {
          if (staticBookIds.includes(data[i].id)) {
            filteredData.push(data[i])
          }
        }
        data = filteredData
      }

      // Map book IDs to their progress percentages
      const bookIdToProgress = readingBook?.reduce((acc, item) => {
        acc[item.book_id] = item.progress_percentage
        return acc
      }, {})

      // Cache colors for genres to ensure consistency
      const genreColorMap = {}
      const getGenreColor = (genre) => {
        if (!genreColorMap[genre]) {
          genreColorMap[genre] = RandomColor()
        }
        return genreColorMap[genre]
      };

      const cleanedData = data.filter((book) => book?.id !== null && book?.id !== undefined);

      // Group books by genres and handle 'Continue Reading', 'New Books', and 'Completed Books' sections
      const groupedBooksMap = {};
      const continueReadingBookIds = new Set();
      const completedBooks = [];
      const sortedData = [...cleanedData].sort((a, b) => new Date(b.created_At) - new Date(a.created_At));


      // Identify books that are in progress or completed
      cleanedData.forEach((book) => {
        if (!book || !book.id) {
          console.warn("Skipping invalid book entry:", book.title);
          return;
        }

        const progress = Number(bookIdToProgress?.[book.id]);
        const isInProgress = progress > 0 && progress < 100;
        const isCompleted = progress === 100;

        const bookWithProgress = bookIdToProgress?.[book.id] !== undefined
          ? { ...book, progress, isProgress: true }
          : book;

        if (isInProgress) {
          if (!groupedBooksMap["Continue Reading"]) {
            groupedBooksMap["Continue Reading"] = {
              name: "Continue Reading",
              items: [],
              bg: "#f57f17",
              matchCount: 0,
            };
          }
          groupedBooksMap["Continue Reading"].items.push(bookWithProgress);
          continueReadingBookIds.add(book.id);
        } else if (isCompleted) {
          completedBooks.push(bookWithProgress); // Store completed books
        }
      });

      // Add 'Recently Added' section excluding books in 'Continue Reading'
      const newBooks = sortedData
        .filter((book) => !continueReadingBookIds.has(book.id))
        .slice(0, 5)
        .map((book) => ({ ...book, label: "New", labelColor: "red" }));
      const newBookIds = new Set(newBooks.map((book) => book.id));

      if (newBooks.length > 0) {
        groupedBooksMap["Recently Added"] = {
          name: "Newly Added",
          items: newBooks,
          bg: "red",
          matchCount: newBooks.length,
        };
      }

      // Group books by genres
      cleanedData.forEach((book) => {
        if (continueReadingBookIds.has(book.id) || newBookIds.has(book.id)) {
          return;
        }

        const genres = book.genre.split(",").map((genre) => genre.trim());
        genres.forEach((genre) => {
          if (!groupedBooksMap[genre]) {
            groupedBooksMap[genre] = {
              name: genre,
              items: [],
              bg: getGenreColor(genre),
              matchCount: 0,
            };
          }
          groupedBooksMap[genre].items.push(book);
          if (bookIdToProgress?.[book.id] !== undefined) {
            groupedBooksMap[genre].matchCount++;
          }
        });
      });

      // Build groupedBooks array with 'Recently Added' first, then 'Continue Reading', then other genres, and finally 'Completed Books'
      const groupedBooks = [];
      if (groupedBooksMap["Recently Added"]) {
        groupedBooks.push(groupedBooksMap["Recently Added"]);
      }
      if (groupedBooksMap["Continue Reading"]) {
        groupedBooks.push(groupedBooksMap["Continue Reading"]);
      }
      Object.keys(groupedBooksMap).forEach((key) => {
        if (key !== "Continue Reading" && key !== "Recently Added") {
          groupedBooks.push(groupedBooksMap[key]);
        }
      });

      // Add 'Completed Books' at the end if any exist
      if (completedBooks.length > 0) {
        groupedBooks.push({
          name: "Completed Books",
          items: completedBooks,
          bg: "#4CAF50", // Green color for completed books
          matchCount: completedBooks.length,
        });
      }

      // Update state
      setAllBooks(groupedBooks);
      // setReadingProgressCount(
      //   groupedBooks.reduce((acc, genre) => acc + genre.matchCount, 0)
      // );
      setTopSectionData(cleanedData);
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getBookReadingProgress()
  }, [])

  useEffect(() => {
    fetchBooks()
  }, [readingBook])

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeClass("fade-out")
      setTimeout(() => {
        setCurrentIndex((prevIndex) => {
          const newIndex = prevIndex < topSectionData.length - 1 ? prevIndex + 1 : 0
          return newIndex
        })
        setTimeout(() => { setFadeClass("fade-in") }, 400)
      }, 200)
    }, 13000)

    return () => clearInterval(interval)
  }, [topSectionData.length])

  return (
    <div className="new-dashboard-wrapper">
      {loading && <div className="loader-overlay"> <div className="loader" /></div>}
      <div className="dashbaord-donut-container">
        <img src={donut_dark} className="dashbaord-donut" alt="" />
      </div>
      <DashboardNavbar setShowModal={setShowModal} />
      <img src={donut_dark_bottom} className="dashbaord-donut-bottom" alt="" />
      <div className="content-wrrapers">
        <DashboardMenu setShowModal={setShowModal} />
        <div className="sub-content-wrrapers">
          <div className="cards-text-container">
            <DashboardBook setSelectedBook={setSelectedBook} description={description} topSectionData={topSectionData} currentIndex={currentIndex} fadeClass={fadeClass} />
            <BookData allBooks={allBooks} scrollRefs={scrollRefs} readingProgressRef={readingProgressRef} getStoryBookLanguages={getStoryBookLanguages} setHoveredIndex0={setHoveredIndex0} setHoverLeave={setHoverLeave} hoveredIndex0={hoveredIndex0} hoverLeave={hoverLeave} setSelectedBook={setSelectedBook} />
            {showLangOption && (<BookLanguageModal updateShowLangOption={() => setShowLangOption(false)} navigateToBook={navigateToBook} />)}
          </div>
        </div>
      </div>
      {<PasswordConfirmation showModal={showModal} setShowModal={setShowModal} route={"/account"} />}
      <div>
      </div>
    </div>
  )
}

export default NewDashboard