const BookPagination = (props) => {
    const { currentPage, prevPage, totalPages, nextPage, congratulationModalOpen } = props
    return (
        <div className="left-bottom">
            <div className="label">
                {currentPage > 1 && (
                    <button style={{ fontSize: "16px", cursor: "pointer", color: "white", backgroundColor: "#8a12ef", border: "none", borderRadius: "13px", padding: "5px 10px", margin: "5px" }} onClick={prevPage}>Previous</button>
                )}
                <span className="range">{`${currentPage} of ${totalPages}`}</span>
                {currentPage < totalPages && (
                    <button style={{ fontSize: "14px", cursor: "pointer", color: "white", backgroundColor: "#8a12ef", border: "none", borderRadius: "13px", padding: "5px 10px", margin: "5px" }} onClick={nextPage}>Next</button>
                )}
                {currentPage === totalPages && (
                    <button onClick={congratulationModalOpen} style={{ fontSize: "14px", cursor: "pointer", color: "white", backgroundColor: "#8a12ef", border: "none", borderRadius: "13px", padding: "5px 10px", margin: "5px" }}>Finish</button>
                )}
            </div>
        </div>
    )
}

export default BookPagination