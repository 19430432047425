import Spinner from "react-bootstrap/Spinner"
import { ImCross } from "react-icons/im"

const TranslationPopup = (props) => {
    const { highlightPosition, selectedLanguage, selectedText, isToolTipLoading, translatedText, handleDoneLanguageClose } = props
    return (
        <div className="english-language-container" style={{ top: `${highlightPosition.top}px`, left: `${highlightPosition.left}px` }}>
            <div className="english-language">
                <div className="english-hd-wrap"><p>Translating to {selectedLanguage}</p></div>
                <div className="English-language-text">
                    <p className="m-0"><strong>Text:</strong>{selectedText}</p>
                    <p className="m-0 translation">{isToolTipLoading ? <Spinner className="pt-10" animation="border" /> : (translatedText || "Translation not found")}</p>
                </div>
                <div className="cross-icon" onClick={handleDoneLanguageClose}>
                    <ImCross style={{ color: "black", fontSize: "20px", fontWeight: "bolder" }} />
                </div>
            </div>
        </div>
    )
}

export default TranslationPopup