import { ProgressBar } from "react-bootstrap"
import { FaBookReader } from "react-icons/fa"

const BookData = (props) => {
    const { allBooks, scrollRefs, readingProgressRef, getStoryBookLanguages, setHoveredIndex0, setHoverLeave, hoveredIndex0, hoverLeave, setSelectedBook } = props
    return (
        <div className="card-main-wraps">
            {allBooks.length === 0 ? (
                <p className="no-data-hd">No Books Found!</p>
            ) : (
                allBooks?.map((book, bookIndex) => (
                    <div className="continue-reading-container" style={{ position: 'relative' }} key={bookIndex}>
                        <div className="reading-hd-container">
                            <h3 className="continue-reading-hd">{book.name}</h3>
                        </div>
                        <div className="all-cards" ref={(el) => (scrollRefs.current[bookIndex] = el)} style={{ display: 'flex', flexWrap: 'wrap' }} >
                            {book?.items?.map((item, itemIndex) => {
                                const uniqueKey = `${bookIndex}-${itemIndex}`
                                return (
                                    <div ref={readingProgressRef} key={itemIndex}>
                                        <div className="card-container" onClick={() => getStoryBookLanguages(item)} onMouseEnter={() => { setHoveredIndex0(uniqueKey); setHoverLeave(false) }} onMouseLeave={() => { setHoveredIndex0(null) }}>
                                            <div className="img-logo-container">
                                                <p className="img-logo" style={{ background: book.bg }}>{book?.name} </p>
                                            </div>
                                            <div className="book-cover">
                                                <img src={item?.book_cover_image} alt="" className="card-img" />
                                                <div className="book-spine"></div>
                                            </div>
                                            <ProgressBar variant="warning" now={item?.progress} style={{ height: "8px", marginTop: "15px" }} />
                                            <p className="dashboard-img-hd"></p>

                                            {hoveredIndex0 === uniqueKey && (
                                                <div
                                                    className={`highlighted-card-container ${hoverLeave ? "hover-leave" : ""}`}
                                                    style={{
                                                        display: "block",
                                                        position: "absolute",
                                                        top: "-10px",
                                                        zIndex: 5,
                                                        transition: "all 0.1s ease-in-out",
                                                    }}
                                                    onClick={() => {
                                                        setSelectedBook(item);
                                                    }}
                                                >
                                                    <div className="img-logo-container">
                                                        <p className="img-logo" style={{ background: book.bg }}>{book.name}</p>
                                                    </div>
                                                    <div className="highlighted-book-cover">
                                                        <img src={item.book_cover_image} alt="" className="card-img" />
                                                        <div className="book-spine"></div>
                                                    </div>
                                                    <div className="highlighted-book-content">
                                                        <div className="highlighted-icons">
                                                            <div className="highlighted-icons-flex-1">
                                                                <div className="card-btn-icon-wrap" onClick={() => { setSelectedBook(item) }} >
                                                                    <div className="highlighted-icon"><FaBookReader style={{ fontSize: "12px", color: "white" }} /></div>
                                                                    <div><p className="card-read-now-btn">Read Now</p></div>
                                                                </div>
                                                            </div>
                                                            <div className="highlighted-icons-flex-2"></div>
                                                        </div>
                                                        <p className="highlighted-dashboard-img-hd">{item?.title}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ))
            )}
        </div>
    )
}

export default BookData