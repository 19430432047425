const DashboardBook = (props) => {
    const { setSelectedBook, description, topSectionData, currentIndex, fadeClass } = props
    return (
        <div className="text-img-container">
            <div className="text-button-container">
                <div className="happy-hd-container">
                    <h1 className="happy-reading-hd">Happy Reading,</h1>
                    <h1 className="jamal-hd">{localStorage.getItem("name")}</h1>
                </div>
                <div className={`happy-text-container  ${fadeClass}`}>
                    <p className="happy-text">{topSectionData[currentIndex]?.description}</p>
                </div>
                <div className="read-now-btn-container" onClick={() => { setSelectedBook(topSectionData[currentIndex]) }}></div>
            </div>
            <div className={`img-container ${fadeClass}`}>
                <div className="dashboard-img-book-cover dashboard-img-book-cover-responsive">
                    <img className="dashboard-img main-dashboard-img-responsive" src={topSectionData[currentIndex]?.book_cover_image} alt="" />
                    <div className="book-spine"></div>
                </div>
                <p className="dashboard-img-hd"> {topSectionData[currentIndex]?.title || ""}</p>
            </div>
            <div className="happy-text-container-responsive">
                <p className="happy-text-responsive">{description}</p>
            </div>
            <div className="read-now-btn-container-responsive">
                <button className="read-now-btn-responsive" onClick={() => { setSelectedBook(topSectionData[currentIndex]) }}>Read Now</button>
            </div>
        </div>
    )
}

export default DashboardBook