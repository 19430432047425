import { ImCross } from "react-icons/im"

const DictionaryPopup = (props) => {
    const { highlightPosition, selectedText, userLanguage, apiResponse, handleCloseDictionary } = props
    return (
        <div className="click-on-dictionary-container" style={{ top: `${highlightPosition.top}px`, left: `${highlightPosition.left}px` }}>
            <div className="click-on-dictionary">
                <div className="click-on-dictionary-hd-wrap"><p>{selectedText}</p></div>
                <div className="click-on-dictionary-text-wrap">
                    <>
                        {userLanguage === "english" ? (
                            apiResponse?.definitions && apiResponse?.definitions?.length === 0 ? <p>not found</p>
                                : apiResponse?.definitions?.map(item => <p>{item ? item?.definition : "No content found!"}</p>))
                            : <p>Feature coming soon</p>}
                    </>
                </div>
                <div className="cross-icon" onClick={handleCloseDictionary}>
                    <ImCross style={{ color: "black", fontSize: "20px", fontWeight: "bolder" }} />
                </div>
            </div>
        </div>
    )
}

export default DictionaryPopup