import { ImCross } from "react-icons/im"

const LanguagePopup = (props) => {
    const { highlightPosition, allLanguagesSupport, selectedLanguage, handleSelectLanguage, handleCloseLanguage, handleDoneLanguage } = props
    return (
        <div className="select-language-container" style={{ top: `${highlightPosition.top}px`, left: `${highlightPosition.left}px` }}>
            <div className="select-language">
                <div className="select-language-hd-wrap">
                    <p>Select Language</p>
                </div>
                <div className="change-languages">
                    <ul>{allLanguagesSupport?.map((lang) => { <li className={`all-language ${selectedLanguage === lang ? "selected-language" : ""}`} onClick={() => { handleSelectLanguage(lang) }} >{" "} {lang}</li> })}</ul>
                </div>
                <div className="cancel-done-btn-wrap">
                    <div className="cencel-btn-container" onClick={handleCloseLanguage} >
                        <p>Cancel </p>
                    </div>
                    <div className="done-btn-container" onClick={() => handleDoneLanguage(selectedLanguage)} >
                        <p>Done</p>
                    </div>
                </div>
                <div className="cross-icon" onClick={handleCloseLanguage} >
                    <ImCross style={{ color: "black", fontSize: "20px", fontWeight: "bolder" }} />
                </div>
            </div>
        </div>
    )
}

export default LanguagePopup