import { FaHeadphones } from "react-icons/fa6"
import { BsFillPlayFill } from "react-icons/bs"
import { MdReplay } from "react-icons/md"
import { AiOutlinePause } from "react-icons/ai"
import { FaMusic } from "react-icons/fa"

const ReplayAndPlayButton = (props) => {
    const { userLanguage, audioLoading, playBook, audioUrl, mainAudioRef, againPlay, generalCall, buttonState, mainAudioResRef, backgroundMusicRef, backgroundMusicVolume, backgroundMusicUrl, handleVolumeChange } = props
    return (
        userLanguage === "urdu" ? (
            <div className="read-func-container">
                {audioLoading ? (
                    <div className="resume-pause-btn" style={{ backgroundColor: "transparent", boxShadow: "unset" }} onClick={playBook}>
                        <p style={{ color: "#FF6B0A", fontSize: "20PX" }}>Audio Loading...</p>
                    </div>
                ) : !audioUrl ? (
                    <div className="resume-pause-btn" onClick={playBook}>
                        <FaHeadphones size={30} color="white" />
                    </div>
                ) : (
                    <div className="audio-volume-container">
                        <div className="audio">
                            <audio controls ref={mainAudioResRef}> <source src={audioUrl} type="audio/mp3" /></audio>
                        </div>
                        <audio ref={backgroundMusicRef} loop volume={backgroundMusicVolume}><source src={backgroundMusicUrl} type="audio/mp3" /></audio>
                        <div className="volume-main-wrap">
                            <div className="icon-volume-wrap">
                                <div className="icon-wrap">
                                    <FaMusic className="volume-icon" />
                                </div>
                                <div className="music-volume-container">
                                    <div className="music-vloume" style={{ position: "relative" }}>
                                        <input type="range" id="volume" min="0" max="1" step="0.1" value={backgroundMusicVolume} onChange={handleVolumeChange} />
                                        <label htmlFor="volume" style={{ position: "absolute", top: 0, left: 0, display: "none", background: "#333", color: "#fff", padding: "5px", borderRadius: "3px", cursor: "default" }}>Background Music Volume:</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ) : (
            <div className="read-func-container">
                <div className="replay-btn" onClick={againPlay}>
                    <MdReplay size={30} color="white" />
                </div>
                <div className="resume-pause-btn" onClick={generalCall}>
                    {buttonState.listen || buttonState.resume ? <BsFillPlayFill size={30} color="white" /> : <AiOutlinePause size={30} color="white" />}
                </div>
            </div>
        )
    )
}

export default ReplayAndPlayButton