const getAge = async (dateString) => {
    var today = await (new Date())
    var birthDate = await (new Date(dateString))
    var age = await (today.getFullYear() - birthDate.getFullYear())
    var m = await (today.getMonth() - birthDate.getMonth())
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age
}

export default getAge